import React, { useEffect, createRef, SetStateAction, useMemo, useCallback, Dispatch } from 'react'
import styled from 'styled-components'
import { ChartDivGroup, ChartLegend } from '../styled'
import ChartJs from 'chart.js'
import { RefineChartData } from '../layout/Chart'

const FieldGroupWrapper = styled.div.attrs(props => ({
  className: 'field is-horizontal'
}))`
  /* input */
  margin-bottom: 8px;

  &.field:not(:last-child) {
    margin-bottom: 10px;
  }

  .field-label.is-normal {
    text-align: left;
    margin-bottom: 0;
    padding-top: 0;
  }

  .field-label .label {
    font-size: 16px;
    color: #000;
    line-height: 30px;
    letter-spacing: -1.04px
  }

  .field-body .input,
  .select:not(.is-multiple),
  .select select {
    width: 100%;
    height: 30px;
  }

  /* select */
  .select select {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 11px;
    font-size: 15px;
    line-height: 28px;
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #000000;
  }

  .select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: #5e7cfe;
  }

  /* p */
  .page-notice {
    position: relative;
    margin-top: 8px;
    padding-left: 7px;
    font-size: 11px;
    color: #666;
    line-height: 1.1;
  }

  .page-notice:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 2px;
    height: 2px;
    background-color: #666;
    border-radius: 50%;
  }

  .page-notice span {
    color: #5e7cfe;
  }

  /* input */
  @media screen and (max-width: 768px) {
    display: flex;

    .field-label {
      flex: 1.5;
      text-align: left;
    }

    .field-body {
      display: flex;
      flex: 4.5;
    }

    .field-body .field {
      flex-shrink: 1;
    }

    .field-body .field:not(.is-narrow) {
      flex-grow: 1;
    }
  }
`
export const ChartWrapper = styled.div`
  float: left;
  width: 100%;
  margin-top: 4px;

  .chart-title {
    margin: 10px auto;
    font-size: 15px;
    color: #000;
    line-height: 1.4;
    text-align: center;
    font-weight: bold;
  }
`

interface LineProps{
  data: any
  options: any
}

export const Line: React.FC<LineProps> = ({ data, options }) => {
  const canvasRef = createRef<HTMLCanvasElement>()

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')

      if (ctx) {
        const chart = new ChartJs(ctx, {
          type: 'line',
          data,
          options,
        })
        return () => {
          chart.destroy()
        }
      }
    }
  }, [data, options, canvasRef])

  return (
    <canvas ref={canvasRef} />
  )
}


const title: any = {
  upto_10_PercentRateOfAllCategory: '상위 10%',
  upto_20_PercentRateOfAllCategory: '상위 20%',
  upto_30_PercentRateOfAllCategory: '상위 30%',
  total_PercentRateOfAllCategory: '전체 평균',
}

interface ChartSelectComparisonTargetProps {
  refineChartData: RefineChartData
  chartSelect: string
  setChartSelect: Dispatch<SetStateAction<string>>
}

const ChartSelectComparisonTarget: React.FC<ChartSelectComparisonTargetProps> = ({refineChartData, chartSelect, setChartSelect}) => {
  const options = {
    title: {
      display: false,
    },
    legend: {
        display: false,
    },
    scales: {
      yAxes:[{
        ticks: {
          beginAtZero: true
        }
      }],
    },
    tooltips: {
      enabled: false
    }
  }

  const handleSelectChange = useCallback(
    (e) => {
      setChartSelect(e.target.value)
    },
    [refineChartData],
  )

  const chartData = useMemo(() => ({
    
    labels: refineChartData.labels,
    datasets: [
      {
        label: '나의 정답률',
        data: refineChartData.myRateOfAllCategory,
        fill: false,
        borderWidth: 1,
        borderColor: '#5e7cfe',
        lineTension: 0,
      },
      {
        label: '상위 10% 정답률',
        data: refineChartData[chartSelect],
        fill: false,
        borderWidth: 1,
        borderColor: '#f94983',
        lineTension: 0,
      },
    ]
  }), [refineChartData, chartSelect])

  return (
    <ChartDivGroup>
      <FieldGroupWrapper>
        <div className="field-label is-normal">
            <label className="label">비교대상 선택</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div className="select">
                <select value={chartSelect} onChange={handleSelectChange}>
                  <option value='upto_10_PercentRateOfAllCategory'>상위 10%</option>
                  <option value='upto_20_PercentRateOfAllCategory'>상위 20%</option>
                  <option value='upto_30_PercentRateOfAllCategory'>상위 30%</option>
                  <option value='total_PercentRateOfAllCategory'>전체 평균</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </FieldGroupWrapper>
      <ChartWrapper>
        <h3 className="chart-title">본인 VS {title[chartSelect]}</h3>
        <Line data={chartData} options={options} />
        {/* <Line /> */}
        <ChartLegend>
          <div className="legend-label legend-my">
            <span className="legend-color"></span>
            나의 정답률
          </div>
          <div className="legend-label legend-target">
            <span className="legend-color"></span>
            {title[chartSelect]} 정답률
          </div>
        </ChartLegend>
      </ChartWrapper>
    </ChartDivGroup>
  )
}

export default ChartSelectComparisonTarget