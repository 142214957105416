import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import ChartSelectComparisonTarget from '../components/ChartSelectComparisonTarget'
import ChartRecommendedContent from '../components/ChartRecommendedContent'
import ChartUserAnswerView from '../components/ChartUserAnswerView'
import Spinner from '../components/Spinner'
import { ChartData, makeRefineChartData, RefineChartData, LocationProps, useChart } from './Chart'
import { Header, Button } from '../styled'
import btnImage from '../assets/bg_btn_s.png'
import ChartTopScore from '../components/ChartTopScore'
import ChartActualBottom from '../components/ChartActualBottom'
import { Location } from 'history'


const AreaButtonGroup = styled.div`
  margin: 0 -2px 15px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  button {
    height: 40px;
    padding: 5px 5px 6px;
    background-color: #888888;
    font-size: 13px;
    line-height: 1.23;
    font-weight: 500;
    letter-spacing: -.52px;
  }

  button.action {
    background-image: url(${btnImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  button span {
    display: block;
    font-weight: bold;
    font-size: 14px;
  letter-spacing: -0.56px;
  }
`


const Wrapper = styled.section`
  min-width: 320px;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
`

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position:fixed;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
`

const Container = styled.div`
  padding: 10px 15px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`

export interface ChartArea {
  area_id?: number
  area_name: string
  average_score: number
  average_score_in_wish_company: number
  my_score: number
  percentage_in_wish_company: number
  percentage_score: number
  previous_year_candidate_score?: number
  total_score: number
}

type ChartDataByAreaId = Record<number, ChartData[]>
type RefineDataByAreaId = Record<number, RefineChartData>

export interface ActualChartData {
  result_area: ChartArea[]
  result_category: ChartDataByAreaId
}

interface ChartProps {
  location: Location<LocationProps>
}


function makeRefineDataByArea(data: ChartDataByAreaId) {
  const result: RefineDataByAreaId = {}
  for (const areaId in data) {
    result[areaId] = makeRefineChartData(data[areaId])
  }
  return result
}


const ActualChart: React.FC<ChartProps> = ({location}) => {
  const [data, setData] = useState<ActualChartData>()
  const [selectedAreaId, setSelectedAreaId] = useState<number>()
  const [chartSelect, setChartSelect] = useState('upto_10_PercentRateOfAllCategory')
  const {locationState, locationBack} = useChart(location, setData)

  const refineDataByAreaId = useMemo(() => {
    if (data) {
      return makeRefineDataByArea(data.result_category)
    }
  }, [data])

  const refineData = useMemo(() => {
    if (selectedAreaId && refineDataByAreaId) {
      return refineDataByAreaId[selectedAreaId]
    }
  }, [selectedAreaId])

  const areas: ChartArea[] = useMemo(() => {
    if (data) {
      return data.result_area.filter(item => item.area_name !== "전체")
    } else {
      return []
    }
  }, [data])

  const allArea: ChartArea | undefined = useMemo(() => {
    if (data) {
      return data.result_area.find(item => item.area_name === "전체")
    }
  }, [data])

  const handleAreaClick = useCallback((areaId?: number) => {
    if (areaId) {
      setSelectedAreaId(areaId)
    }
  }, [])

  useEffect(() => {
    if (areas[0]) {
      setSelectedAreaId(areas[0].area_id)
    }
  }, [areas])

  return (
      <>
        {!data ? 
          <Overlay>
            <Spinner />
          </Overlay> :
          <Wrapper>
            <Header>
              <h1 className="title">실전모의고사 {locationState.area.trial_time_name} 상세 분석</h1>
              <button className="btn-back" onClick={() => locationBack()}>&lt; 뒤로가기</button>
            </Header>
            <Container>
              {allArea && 
                <ChartTopScore 
                  name={locationState.area.trial_time_name}
                  currentScore={allArea.my_score}
                  totalScore={allArea.total_score}
                />}
              
              <AreaButtonGroup>
                {areas.map(item => 
                  <Button className={`time-btn ${item.area_id === selectedAreaId ? 'action' : ''}`}
                    key={item.area_id}
                    onClick={(e) => handleAreaClick(item.area_id)}>
                    {item.area_name}<span>{item.my_score.toFixed(0)}점</span>
                  </Button>
                )}
              </AreaButtonGroup>
              {selectedAreaId && <div>
                <ChartUserAnswerView
                  area={locationState.area}
                  data={data.result_category[selectedAreaId]}
                  score={locationState.score}
                />
                {refineData && <>
                  <ChartSelectComparisonTarget
                    refineChartData={refineData}
                    chartSelect={chartSelect}
                    setChartSelect={setChartSelect}/>
                  <ChartRecommendedContent
                    weakCategories={refineData.weakCategories[chartSelect]} />
                  <ChartActualBottom chartAreaData={data.result_area} />
                </>}                
              </div>}
            </Container>
          </Wrapper>
        }
      
      </>
  )
}

export default ActualChart