import React, { useMemo, useEffect, createRef } from 'react'
import { ChartData } from '../layout/Chart'
import ChartJs from 'chart.js'
import styled from 'styled-components'
import { ChartLegend } from '../styled'
import Area from '../types/Area'

export const ChartWrapper = styled.div`
  float: left;
  width: 100%;
  margin-top: 4px;

  .chart-title {
    margin: 10px auto;
    font-size: 15px;
    color: #000;
    line-height: 1.4;
    text-align: center;
    font-weight: bold;
  }
`


interface HorizontalBarProps{
  data: any
  options: any
}

const HorizontalBar: React.FC<HorizontalBarProps> = ({ data, options }) => {
  const canvasRef = createRef<HTMLCanvasElement>()

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')

      if (ctx) {
        const chart = new ChartJs(ctx, {
          type: 'horizontalBar',
          data,
          options,
        })
        return () => {
          chart.destroy()
        }
      }
    }
  }, [data, options, canvasRef])

  return (
    <canvas ref={canvasRef} />
  )
}


interface ChartLastYearProps {
  data: ChartData | undefined
  area: Area
}

const ChartLastYear: React.FC<ChartLastYearProps> = ({data, area}) => {
  const chartData: any = useMemo(() => {
    if (!data) {
      return
    }
    return {
      data: {
        datasets: [
          {
            label: '본인',
            data: [data.user_score],
            fill: false,
            borderWidth: 0,
            backgroundColor: '#5e7cfe',
            pointRadius: 0,
          },
          {
            label: '19 상반기 합격자 평균',
            data: [data.previous_year_candidate_score],
            fill: false,
            borderWidth: 0,
            backgroundColor: '#f94983',
            pointRadius: 0,
          },
        ]
      },
      options: {
        responsive: true,
        title: {
          display: false,
        },
        legend: {
            display: false,
        },
        scales: {
          xAxes:[{
            ticks: {
              min: 0,
              max: data.question_score
            }
          }],
          yAxes:[{
            maxBarThickness: 30,
          }]
        },
      }
    }
    
  }, [data])

  if (!data || !data.previous_year_candidate_score) {
    return null
  }
  return (
    <ChartWrapper>
      <h3 className="chart-title">{area.name}영역 정답 개수(본인 vs 19 상반기 합격자)</h3>
      {chartData && <HorizontalBar data={chartData.data} options={chartData.options} />}
      <ChartLegend>
        <div className="legend-label legend-my">
            <span className="legend-color"></span>
            본인
        </div>
        <div className="legend-label legend-target">
            <span className="legend-color"></span>
            19 상반기 합격자 평균
        </div>
      </ChartLegend>
    </ChartWrapper>
  )
}

export default ChartLastYear