import React, {useCallback, useMemo } from 'react'
import styled from 'styled-components'
import AnswerInput from './AnswerInput'
import { Button } from '../styled'
import { debounce } from 'lodash'

const ButtonWrapper = styled.div`
  padding: 20px 15px;
  border-top: 1px solid #eaeaea;
  background-color: #f5f5f5;
`

const AnswerInputWrapper = styled.div`
  margin-top: 8px;
  padding: 0 15px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .field {
    float: left;
    width: 50%;
    margin-bottom: 10px;
  }

  .field:nth-child(2n+1) {
    padding-right: 8px;
  }

  .field:nth-child(2n) {
    padding-left: 8px;
  }

  .label {
    font-size: 13px;
    color: #000;
  }

  .label:not(:last-child) {
    margin-bottom: 6px;
  }

  input {
    height: 30px;
    text-align: center;
  }
`

function makeQuestionLabel(chunkedDataIndex: number, questionCount: number) {
  return `${chunkedDataIndex * 5 + 1}번 ~ ${chunkedDataIndex * 5 + questionCount}번`
}

// export type AnswerBundleRecord = Record<number, string>
export type AnswerInputGroupOnChangeAction = (prev: Array<number | null>) => Array<number | null>

export interface AnswerInputGroupProps {
  numberOfAnswers: number
  value: Array<number | null>
  onChange: (action: AnswerInputGroupOnChangeAction) => any
  onSave: () => any
}

function replace<T>(dest: T[], source: T[], index: number) {
  const result = dest.slice()
  for (let i = 0; i < source.length; ++i) {
    result[i + index] = source[i]
  }
  return result
}

function createInputValues(numberOfAnswers: number, value: Array<number | null>) {
  const numberOfInputs = Math.ceil(numberOfAnswers / 5)
  const inputs: Array<number | null>[] = []
  for (let i = 0; i < numberOfInputs; ++i) {
    const valueIndex = i * 5
    inputs[i] = value.slice(valueIndex, valueIndex + 5)
  }
  return inputs
}

function pad<T>(array: T[], maxLength: number, padding: T) {
  if (array.length >= maxLength) {
    return array
  }
  const result = array.slice()
  while (result.length < maxLength) {
    result.push(padding)
  }

  return result
}

const AnswerInputGroup: React.FC<AnswerInputGroupProps> = ({ numberOfAnswers, value, onChange, onSave }) => {
  const inputValues = useMemo(() => createInputValues(numberOfAnswers, value), [value, numberOfAnswers])

  const handleAnswerInputChange = useCallback((inputIndex: number, value: number[]) => {
    onChange(prev => replace(prev, pad(value, inputValues[inputIndex].length, null), inputIndex * 5))
  }, [onChange, inputValues])

  const handleSaveButtonClick = useCallback(() => {
    onSave()
  }, [onSave])

  return (
    <>
      <AnswerInputWrapper>
        {inputValues.map((inputValue, idx) =>          
          <div className="field" key={idx}>
            <label className="label">{makeQuestionLabel(idx, inputValue.length)}</label>
            <div className="control">
              <AnswerInput
                index={idx}
                value={inputValue}
                onChange={handleAnswerInputChange}
                maxLength={Math.min(5, numberOfAnswers - 5 * idx)}
              />
            </div>
          </div>
        )}
      </AnswerInputWrapper>    

      <ButtonWrapper>
        <Button 
          className="gradient" 
          onClick={debounce(handleSaveButtonClick, 350)}
          // disabled={!answerIsValid}
        >
          채점 결과 바로 확인하기
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default AnswerInputGroup