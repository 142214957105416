import React, { useMemo, useEffect, createRef } from 'react'
import { RefineChartData } from '../layout/Chart'
import { ChartDivGroup, ChartLegend, ChartTableWrapper} from '../styled'
import { ChartArea } from '../layout/ActualChart'
import { Line, ChartWrapper } from './ChartSelectComparisonTarget'

interface ChartActualBottomProps {
  chartAreaData: ChartArea[]
}
interface PreviousChartData {
  labels: String[]
  me: number[]
  previous: any[]
}
function makeChartData(data: ChartArea[]) {
  const areas = data.filter(area => area.area_id)
  const result: PreviousChartData = {
    labels: [],
    me: [],
    previous: []
  }
  for(const area of areas) {
    result.labels.push(area.area_name)
    result.me.push(area.my_score)
    result.previous.push(area.previous_year_candidate_score)
  }
  return result
}

const ChartActualBottom: React.FC<ChartActualBottomProps> = ({chartAreaData}) => {

  console.log(chartAreaData)
  const options = {
    title: {
      display: false,
    },
    legend: {
        display: false,
    },
    scales: {
      yAxes:[{
        ticks: {
          beginAtZero: true
        }
      }],
    },
    tooltips: {
      enabled: false
    }
  }

  const chartData = useMemo(() => {
    const data = makeChartData(chartAreaData)

    return {
      labels: data.labels,
      datasets: [
        {
          label: '본인 정답 수',
          data: data.me,
          fill: false,
          borderWidth: 1,
          borderColor: '#5e7cfe',
          lineTension: 0
        },
        {
          label: '합격자 정답 수',
          data: data.previous,
          fill: false,
          borderWidth: 1,
          borderColor: '#f94983',
          lineTension: 0
        },
      ]
    }
  }, [chartAreaData])

  const checkChartEnable = (chartAreaData: ChartArea[]) => {
    const areas = chartAreaData.filter(area => area.area_name !== '전체')
                    .filter(area => area.previous_year_candidate_score)
    return areas.length !== 0
  }

  return (
    <ChartDivGroup>
      <ChartTableWrapper>
        <table className="table is-bordered">
          <colgroup>
            <col width="19%" />
            <col width="15%" />
            <col width="12%" />
            <col width="15%" />
            <col width="12%" />
            <col width="15%" />
            <col width="12%" />
          </colgroup>
          <thead>
            <tr>
              <th rowSpan={2}>영역명</th>
              <th rowSpan={2}>점수</th>
              <th colSpan={2}>평균점수</th>
              <th colSpan={2}>백분율(%)</th>
            </tr>
            <tr>
              <th>전체</th>
              <th>지원회사</th>
              <th>전체</th>
              <th>지원회사</th>
            </tr>
          </thead>
          <tbody>
            {chartAreaData.map(area => 
              <tr key={area.area_name}>
                <th>{area.area_name === '전체' ? '총점' : area.area_name}</th>
                <td className={area.area_name === '전체' ? 'total' : ''}>{area.my_score}</td>
                <td className={area.area_name === '전체' ? 'total' : ''}>{area.average_score.toFixed(0)}</td>
                <td className={area.area_name === '전체' ? 'total' : ''}>{area.average_score_in_wish_company.toFixed(0)}</td>
                <td className={area.area_name === '전체' ? 'total' : ''}>{area.percentage_score.toFixed(0)}</td>
                <td className={area.area_name === '전체' ? 'total' : ''}>{area.percentage_in_wish_company.toFixed(0)}</td>
              </tr>
            )}
          </tbody>
        </table>

        {checkChartEnable(chartAreaData) && 
          <ChartWrapper>
            <h3 className="chart-title">영역별 정답 개수 (본인 VS 상반기 합격자)</h3>
            <Line data={chartData} options={options} />
            {/* <Line /> */}
            <ChartLegend>
              <div className="legend-label legend-my">
                <span className="legend-color"></span>
                나의 정답 수
              </div>
              <div className="legend-label legend-target">
                <span className="legend-color"></span>
                합격자 정답 수
              </div>
            </ChartLegend>
          </ChartWrapper>
        }        
      </ChartTableWrapper>
    </ChartDivGroup>
  )
}

export default ChartActualBottom