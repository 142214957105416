import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import topImage from '../assets/img_top.png'
import BasicInfo from '../components/BasicInfo'
import BookTrial, { BookTrialList }  from '../types/Book'
import { getBookList } from '../api'
import SessionData from '../session'
import QuestionLayer from './Question'
import { BookContext, UserInfoContext } from '../context'
import UserInfo from '../types/UserInfo'
import { BookItem } from '../components/BookSelectModal'

const Wrapper = styled.div`
  position: relative;
  min-width: 320px;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  background-color: #f5f5f5;
`

const TopImage = styled.div`
  img {
    display: block;
    width: 100%;
  }
`

const BookView = styled(BookItem)`
  padding: 10px 15px;
  background-color: #fff;

    img {
      width: 25%;
      max-width: 85px;
    }

    p {
      width: calc(100% - 25%);
      max-width: calc(100% - 85px);
      max-height: 60px;
    }
      p span {
        -webkit-line-clamp: 3;
      }
`

const Main: React.FC = () => {
  const [book, setBook] = useState<BookTrial | null>(null)
  const [bookList, setBookList] = useState<BookTrialList>([])
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)

  useEffect(() => {
    const apiBookList = async () => {
      const { data } = await getBookList({ is_active: true })
      setBookList(data.results)
    }
    apiBookList()

    SessionData.userInfo && setUserInfo(SessionData.userInfo)
  }, [])

  useEffect(() => {
    setBook(bookList[0])
  }, [bookList])

  return (
      <Wrapper>
        <TopImage>
          <img src={topImage} alt=""></img>
        </TopImage>
        <BasicInfo 
          setBook={setBook} 
          book={book} 
          bookList={bookList}
          setUserInfo={setUserInfo}
          userInfo={userInfo}
          ></BasicInfo>
        {book && 
          <>
            <BookView>
              <img src={book.list_image} alt=""></img>
              <p>
                <span>{book.subject}</span>
              </p>
            </BookView>
            <BookContext.Provider value={book}>
              <UserInfoContext.Provider value={userInfo}>
                <QuestionLayer setUserInfo={setUserInfo} />
              </UserInfoContext.Provider>
            </BookContext.Provider>
          </>
        }        
      </Wrapper>
  )
}

export default Main
