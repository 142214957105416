import React, { useState, useEffect, useContext, useCallback } from 'react'
import styled from 'styled-components'
import { AreaList } from '../types/Area'
import { BookContext, UserInfoContext } from '../context'
import { Notice } from '../styled'
import { AnswerListRecordByAreaId } from '../types/Answer'
import { PracticeQueryBuilder, PracticeQuery } from './query-builder'
import UserAnswerEditorContainer from './UserAnswerEditorContainer'
import { makeUserAnswer, saveAnswers, makeFetchData, UserAnswersPerArea } from './AnswerData'

const Wrapper = styled.div`
`
function validateAnswerInputs(userAnswers: AnswerListRecordByAreaId, selectedAreaId: number) {
  const selectedAreaAnswers = userAnswers[selectedAreaId]
  for (const answer of selectedAreaAnswers) {
    if (!answer.userAnswer) {
      alert('정답을 모두 입력해주세요.')
      return false
    }
  }
  return true
}

interface PracticeProps {
  areas: AreaList
}

const Practice: React.FC<PracticeProps> = ({areas}) => {
  const book = useContext(BookContext)
  const userInfo = useContext(UserInfoContext)  

  // query

  const [query, setQuery] = useState<PracticeQuery>({ area: null })
  
  const selectedArea = query.area

  useEffect(() => {
    setQuery({ area: areas[0] })    
  }, [areas])

  // fetch data

  const fetchData = useCallback<() => Promise<AnswerListRecordByAreaId>>(async () => {
    const result = await makeFetchData(book, areas, userInfo)
    return result
  }, [userInfo, book, areas])

  // save data

  const onSave = useCallback(async (areaAnswers: AnswerListRecordByAreaId, userAnswersPerArea: UserAnswersPerArea) => {
    if (!selectedArea) {
      return false
    }

    const userAnswers = makeUserAnswer(areaAnswers, userAnswersPerArea)
    const validation = validateAnswerInputs(userAnswers, selectedArea.id)
    if (!validation) {
      return false
    } 
    await saveAnswers({[selectedArea.id]: userAnswers[selectedArea.id]}, userInfo)

    return true
  }, [selectedArea, userInfo])

  if (!selectedArea) {
    return null
  }

  return (
    <Wrapper>
      <PracticeQueryBuilder
        areas={areas}
        value={query}
        onChange={setQuery}
      >
        <Notice>
          <div className="header">
              {book && book.subject}
              <strong>실력점검+합격예측 서비스 이용 안내</strong>
          </div>
          <div className="body">
            <p><strong>원하는 영역을 선택 후 정답 입력</strong>을 진행해주세요.<span>(1번에 1개 영역만 입력 가능)</span></p>
            <p>페이지와 문제 번호를 확인하고 정답을 입력해 주세요</p>
            <p><strong>모든 문항의 정답을 입력</strong>해야만 결과 확인이 가능합니다.</p>
            <p>분석 결과 보기 이후에도 정답 입력 내용 수정이 가능합니다. </p>
          </div>
        </Notice>
      </PracticeQueryBuilder>

      <UserAnswerEditorContainer 
        fetch={fetchData}
        save={onSave}
        area={selectedArea}
      />
    </Wrapper>
  )
}

export default Practice