import React from 'react'
import Answer, { AnswerEditorMode } from '../types/Answer'
import UserAnswerView from './UserAnswerView'
import AnswerInputGroup, { AnswerInputGroupProps } from './AnswerInputGroup'
import Area from '../types/Area'

export interface UserAnswerEditorProps extends Omit<AnswerInputGroupProps, 'numberOfAnswers'> {
  mode: AnswerEditorMode
  onModeChange: (mode: AnswerEditorMode) => any
  answers: Answer[]
  area: Area
}

const UserAnswerEditor: React.FC<UserAnswerEditorProps> = ({ mode, onModeChange, answers, value, onChange, onSave, area, children }) => {
  if (!answers) return null
  switch (mode) {
    case AnswerEditorMode.VIEW: {
      return (
        <UserAnswerView
          area={area}
          tableColumns={5}
          answers={answers}
          onMode={onModeChange}
        >
          {children}
        </UserAnswerView>
      )
    }
    default: {
      return (
        <AnswerInputGroup
          numberOfAnswers={answers.length}
          value={value}
          onChange={onChange}
          onSave={onSave}
        />
      )
    }
  }
}

export default UserAnswerEditor