import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import ChartSelectComparisonTarget from '../components/ChartSelectComparisonTarget'
import ChartRecommendedContent from '../components/ChartRecommendedContent'
import ChartUserAnswerView from '../components/ChartUserAnswerView'
import Spinner from '../components/Spinner'
import { ChartData, makeRefineChartData, LocationProps, useChart } from './Chart'
import ChartTopScore from '../components/ChartTopScore'
import ChartLastYear from '../components/ChartLastYear'
import { Location } from 'history'
import { Header } from '../styled'


const Wrapper = styled.section`
  min-width: 320px;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
`

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position:fixed;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
`

const Container = styled.div`
  padding: 10px 15px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`

interface ChartProps {
  location: Location<LocationProps>
}

const PracticeChart: React.FC<ChartProps> = ({location}) => {
  const [data, setData] = useState<ChartData[]>([])
  const refineChartData = useMemo(() => makeRefineChartData(data), [data])
  const {locationState, locationBack } = useChart(location, setData)
  const [chartSelect, setChartSelect] = useState('upto_10_PercentRateOfAllCategory')

  return (
      <>
      {!data.length && 
      <Overlay>
        <Spinner />
      </Overlay>}
      {locationState &&
      <Wrapper>
        <Header>
          <h1 className="title">{locationState.area.name} 상세 분석</h1>
          <button className="btn-back" onClick={() => locationBack()}>&lt; 뒤로가기</button>
        </Header>
        <Container>
          <ChartTopScore 
            name={locationState.area.name}
            currentScore={locationState.score.me}
            totalScore={locationState.score.total}
          />
          <ChartUserAnswerView
            area={locationState.area}
            data={data}
            score={locationState.score}
          />
          <ChartSelectComparisonTarget
            refineChartData={refineChartData}
            chartSelect={chartSelect}
            setChartSelect={setChartSelect}/>
          <ChartRecommendedContent
            weakCategories={refineChartData.weakCategories[chartSelect]} />
          <ChartLastYear data={data.find(category => category.category_name === '전체')}
            area={locationState.area}/>
        </Container>
      </Wrapper>}
      
      </>
  )
}

export default PracticeChart