import UserInfo from '../types/UserInfo'
import Answer, { AnswerListRecordByAreaId, AnswerList } from '../types/Answer'
import { getQuestions, getUserAnswers, postUserAnswers } from '../api'
import { reduce, cloneDeep } from 'lodash'
import Book from '../types/Book'
import { AreaList } from '../types/Area'
import {showSpinner, closeSpinner} from './Spinner'

export type UserAnswersPerArea = Record<number, Array<number | null>>

async function fecthQuestions(bookTrialId: number, areaId: number) {
  const { data } = await getQuestions({ trial: bookTrialId, area: areaId })
  return data.results
}

async function fetchUserAnswers(userInfoId: number, areaId: number) {
  const { data } = await getUserAnswers({user_id: userInfoId, area_id: areaId })
  return data.results
}

async function makeAllAreaAnswers(bookTrialId: number, areaIdList: number[]) {
  const result: AnswerListRecordByAreaId = {}
  for (const id of areaIdList) {
    result[id] = await fecthQuestions(bookTrialId, id)
    result[id] = result[id].map(q => ({
      id: null,
      question: q.id,
      answer: q.answer,
      number: q.number,
      score: q.score,
      correct_rate: q.correct_rate,
      category_name: q.category_name,
      userAnswer: ''
    }) as Answer)
  }
  return result
}

export async function saveAnswers(areaAnswers: AnswerListRecordByAreaId, userInfo: UserInfo | null) {
  const answers = reduce(areaAnswers, (a, b) => [...a, ...b], [] as AnswerList)
  await postUserAnswers({ answers, userInfo })
}

export function makeUserAnswer(areaAnswers: AnswerListRecordByAreaId, value: UserAnswersPerArea) {
  const result = cloneDeep(areaAnswers)
  for (const areaId in result) {
    result[areaId].forEach((question, idx) => { 
      if (value[areaId] && value[areaId][idx]) {
        question.userAnswer = String(value[areaId][idx])
      }
    })
  }
  return result
}

export async function makeFetchData(book: Book | null, areas: AreaList, userInfo: UserInfo | null) {
  if (!book) {
    return Promise.reject()
  }
  showSpinner()
  const answerData = await makeAllAreaAnswers(book.id, areas.map(a => a.id))

  for (const area of areas) {
    if (!userInfo || !userInfo.id) {
      continue
    }

    const answers = await fetchUserAnswers(userInfo.id, area.id)   

    if (!answers.length) {
      continue
    }
    answerData[area.id] = answers
  }
  closeSpinner()
  return answerData
}