import styled from 'styled-components'
import btnImage from './assets/bg_btn.png'


export const Focus = styled.a`
`

export const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
  color: #000;
  font-weight: bold;
`

export const Button = styled.button`
  width: 100%;
  padding: 13px 0;
  background-color: #585b60;
  border: 0;
  border-radius: 40px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.gradient {
    background-image: url(${btnImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.is-text {
    display: block;
    padding: 0;
    border: 0;
    background-color: #fff;
    font-size: 16px;
    color: #5e7cfe;
    font-weight: normal;
  }

  &.half {
    width: calc(50% - 5px);
  }

  &.half.left {
    margin-right: 5px;
  }

  &.half.right {
    margin-left: 5px;
  }

  // 영역별 버튼
  &.time-btn {
    float: left;
    width: calc(25% - 4px);
    margin: 0 2px;
  }

  &.time-btn.bg-color {
    background-color: #b2b2b2;
  }

  &.time-btn.active {
    background-color: #5e7cfe;
  }
`

export const WhiteWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  padding: 20px 15px;
  background-color: #fff;
`

export const Notice = styled.div`
  background-color: #f5f5f5;

  .header {
    padding: 14px 10px 10px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
    color: #666;
    line-height: 1.29;
  }

  .header strong {
    display: block;
  }

  .body {
    padding: 10px 10px 15px;
  }

  .body p {
    position: relative;
    padding-left: 9px;
    font-size: 11px;
    color: #666;
    line-height: 1.64;
  }

  .body p:before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 0;
    width: 2px;
    height: 2px;
    background-color: #666;
    border-radius: 50%;
  }

  .body p span {
    display: block;
  }
`

export const Table = styled.div`
  margin-bottom: 10px;

  table {
    width: 100%;
  }

  table td:not([align]),
  table th:not([align]) {
    padding: 7px 0;
    font-size: 13px;
    text-align: center;
    vertical-align: middle;
  }

  .o {
    color: #5e7cfe
  }

  .x {
    color: #f94983
  }
`

export const ChartDivGroup = styled.div`
  position: relative;
  padding-bottom: 10px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`

export const ChartTableWrapper = styled.div`
  .table {
    width: 100%;
    /* margin-bottom: 10px; */
  }

  .table:not(:last-child) {
    margin-bottom: 10px;
  }

  .table td:not([align]),
  .table th:not([align]) {
    padding: 7px 0;
    font-size: 11px;
    text-align: center;
    letter-spacing: -0.68px;
    vertical-align: middle;
  }

  .weport-contents-tabel td:not([align]),
  .weport-contents-tabel th:not([align]) {
    font-size: 13px;
  }

  tbody th {
    background-color: #f4f4f5;
  }

  .total {
    color: #f94983;
    font-weight: bold;
  }

  .percent {
    color: #5e7cfe;
    font-weight: bold;
  }
`

export const ChartLegend = styled.div`
  padding: 0 26px;
  text-align: center;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  span {
    display: inline-block;
  }

  .legend-label {
    display: inline-block;
    font-size: 11px;
    color: #666;
    text-align: center;
    line-height: 1.9;
  }

  .legend-label:last-child {
    margin-left: 25px;
  }

  .legend-color {
    width: 35px;
    height: 6px;
    margin-right: 5px;
  }

  .legend-label.legend-my .legend-color {
    background-color: #5e7cfe;
  }

  .legend-label.legend-target .legend-color {
    background-color: #f94983;
  }
`

export const Header = styled.div`
  position: relative;
  padding: 14px 0;
  min-height: 48px;
  background-color: #5e7cfe;

  .title {
    font-size: 21px;
    color: #fff;
    line-height: 1;
    text-align: center;
  }

  .title:not(:last-child) {
    margin-bottom: 0;
  }

  .btn-back {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 10px;
    width: auto;
    height: 40px;
    background-color: transparent;
    border: 0;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
  }
`