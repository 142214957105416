import React from 'react'
import styled from 'styled-components'


const ScoreBox = styled.div`
  width: 100%;
  margin: 5px auto 15px;
  padding: 12px;
  background-color: #f5f5f5;
  border: 1px solid #5e7cfe;
  border-radius: 3px;
  font-size: 15px;
  color: #000;
  text-align: center;

  strong {
    color: #f94983;
  }
`
interface ChartTopScoreProps {
  name?: string
  currentScore: number
  totalScore: number
}

const ChartTopScore: React.FC<ChartTopScoreProps> = ({name, currentScore, totalScore}) => {
  return (
    <ScoreBox>
      <span>{name}</span> 채점 결과 : <strong>{currentScore}점</strong> / { totalScore }점
    </ScoreBox>
  )
}

export default ChartTopScore