import React, { ChangeEvent, useCallback } from 'react'
import styled from 'styled-components'
import FieldGroup from '../layout/FieldGroup'
import Area from '../types/Area'
import SelectedAreaDescription from './SelectedAreaDescription'
import { Button } from '../styled'

const TopArea = styled.div`
  padding: 15px;
`

const AreaSelect = styled.div`
  margin-top: 15px;
`

export interface PracticeQuery {
  area: Area | null
}

interface PracticeQueryBuilderProps {
  areas: Area[]
  value: PracticeQuery
  onChange: (value: PracticeQuery) => any
}

export const PracticeQueryBuilder: React.FC<PracticeQueryBuilderProps> = ({ areas, value, onChange, children }) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const id = Number(e.target.value)
    const nextArea = areas.find(item => item.id === id)
    if (!nextArea) {
      return
    }

    onChange({ area: nextArea })
  }, [areas, onChange])

  const { area } = value

  return (
    <TopArea>
      {children}

      <AreaSelect>
        <FieldGroup label="영역 선택">
          <div className="select">
            <select onChange={handleChange}>
              {areas.map(item =>
                item.question_count &&
                <option key={item.id} value={item.id}>{item.name}</option>
              )}
            </select>
          </div>
          {area && <SelectedAreaDescription area={area}/> }
        </FieldGroup>
      </AreaSelect>
    </TopArea>
  )
}


const AreaButtonGroup = styled.div`
  margin: 5px -2px 12px;
  padding: 0 15px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`

export interface ActualQuery {
  area: Area | null
}

interface AutualQueryBuilderProps {
  areas: Area[]
  value: ActualQuery
  onChange: (value: ActualQuery) => any
}

export const ActualQueryBuilder: React.FC<AutualQueryBuilderProps> = ({ areas, value, onChange, children }) => {
  const handleChange = useCallback((area: Area) => {
    const id = area.id
    const nextArea = areas.find(item => item.id === id)
    if (!nextArea) {
      return
    }

    onChange({ area: nextArea })
  }, [areas, onChange])

  const { area: selectedArea } = value

  return (
    <>
      {children}

      <AreaButtonGroup>
        {areas.map((area: Area) => 
          area.question_count && <Button className={`time-btn bg-color ${area.name === (selectedArea ? selectedArea.name : '') ? 'active' : ''}`}
            key={area.id}
            onClick={() => handleChange(area)}>
            {area.name}
          </Button>
        )}
      </AreaButtonGroup>
    </>
  )
}

