import React, { useCallback, FormEvent, useContext } from 'react'
import { UserInfoContext } from '../context'

/**
 * 1~5 사이의 숫자로 된 정답을 5개씩 묶어서 처리하는 input
 */

interface AnswerInputProps {
  index: number
  value: Array<number | null>
  onChange: (index: number, value: number[]) => any
  maxLength: number
}


const AnswerInput: React.FC<AnswerInputProps> = ({ index, value, onChange, maxLength }) => {
  const userInfo = useContext(UserInfoContext)

  const handleChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    if (!userInfo) {
      alert('이용 정보를 등록해주세요.')
      const el: HTMLInputElement | null = document.querySelector('#username-input')
      el && el.focus()
      return
    }

    const nextValue = e.currentTarget.value
    const lastValue = Number(nextValue.slice(-1))

    if (nextValue.length > maxLength) {
      return
    } else {
      if (lastValue !== 0 && (!lastValue|| lastValue > 5)) {
        return
      }
    }

    onChange(
      index, 
      nextValue
        .split('')
        .map(c => parseInt(c, 10)),
    )
  }, [index, onChange, maxLength, userInfo])

  return (
    <input 
      className="input"
      type="text"
      placeholder={"12345".substr(0, maxLength)}
      value={value.join('') || ''}
      onChange={handleChange}
    />
  )
}

export default AnswerInput