import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import BookSelect from './BookSelect'
import BookTrial, { BookTrialList } from '../types/Book'
import UserInfoForm from './UserInfoForm'
import UserInfo from '../types/UserInfo'

interface BasicInfoProps {
  setBook: Dispatch<SetStateAction<BookTrial | null>>,
  book?: BookTrial | null,
  bookList: BookTrialList,
  setUserInfo: Dispatch<SetStateAction<UserInfo | null>>,
  userInfo?: UserInfo | null
}

const Wrapper = styled.div`
`

const BasicInfo: React.FC<BasicInfoProps> = ({setBook, book, bookList, setUserInfo, userInfo}) => {
  return (
    <Wrapper>
      <BookSelect setBook={setBook} book={book} bookList={bookList}></BookSelect>
      <UserInfoForm setUserInfo={setUserInfo} book={book} userInfo={userInfo}></UserInfoForm>      
    </Wrapper>
  )
}

export default BasicInfo