import React, { MouseEvent, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import BookTrial, { BookTrialList } from '../types/Book'

const ModalCard = styled.div.attrs(props => ({
  className: 'modal-card'
}))`
  width: calc(100% - 30px) !important;
  max-width: 640px;

    .modal-card-head {
      padding: 14px 15px;
      background-color: #5e7cfe;
      border-radius: 0;
    }

      .modal-card-title {
        font-size: 18px;
        color: #fff;
        font-weight: bold;
      }

    .modal-card-body {
      padding: 0;
    }
`

export const BookItem = styled.div`
  min-height: 45px;
  padding: 4px 7px 4px 2px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }

    img {
      display: inline-block;
      width: 10%;
      min-width: 35px;
      vertical-align: middle;
    }

    p {
      display: inline-block;
      width: calc(100% - 10%);
      max-width: calc(100% - 35px);
      max-height: 36px;
      padding-left: 4px;
      font-size: 14px;
      color: #000;
      line-height: 1.3;
      vertical-align: middle;
      box-sizing: border-box;
      word-wrap:break-word;
    }

      span {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
`
interface ModalProps {
  setBook: Dispatch<SetStateAction<BookTrial | null>>,
  setActive: Dispatch<SetStateAction<boolean>>,
  isActive: boolean,
  bookList: BookTrialList
}

const Modal: React.FC<ModalProps> = ({setBook, setActive, isActive, bookList}) => {  

  const handleBookClick = (e: MouseEvent<HTMLElement>, book: any) => {
    setBook(book)
    setActive(false)
  }

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <ModalCard>
        <header className="modal-card-head">
          <p className="modal-card-title">교재 선택</p>
          <button className="delete" onClick={()=>setActive(false)}></button>
        </header>
        <section className="modal-card-body">
          <ul>
            {bookList.map(book => 
              (
                <BookItem
                  as="li"
                  key={book.id}
                  onClick={e => handleBookClick(e, book)}>
                  <img src={book.list_image} alt=""></img>
                  <p>{book.subject}</p>
                </BookItem>
              )
            )}
          </ul>
        </section>
      </ModalCard>
    </div>
  )
}

export default Modal