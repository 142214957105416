import React from 'react'
import '../assets/spinner.css'

const Spinner: React.FC = () => {
  return (
    <>
      <div className="overlay" id="spinner">
        <div className="spinner-box">
          <div className='spinner'>Loading...</div>
        </div>
      </div>
    </>
  )
}

export function showSpinner() {
  const el: HTMLElement | null = document.getElementById('spinner')
  if(el) el.style.display = 'block'
}

export function closeSpinner() {
  const el: HTMLElement | null = document.getElementById('spinner')
  if(el) el.style.display = 'none'
}

export default Spinner