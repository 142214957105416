import UserInfo from './types/UserInfo'

interface SessionProps {
  trialId?: number,
  userInfo?: UserInfo
}

class SessionData implements SessionProps{
  trialId?: number
  userInfo?: UserInfo

  constructor(data?: any) {
    if (data) {
      this.userInfo = data.userInfo
    }
    this.save()
  }

  setUserInfo(user: UserInfo) {
    this.userInfo = user
    this.save()
  }

  private save() {
    sessionStorage.setItem('bookTrial', JSON.stringify(this))
  }
}


const jsonObj = sessionStorage.getItem('bookTrial')
let data = new SessionData()
if (jsonObj) {
  data = new SessionData(JSON.parse(jsonObj))
}

export default data