import React from 'react'
import Main from './layout/Main'
import ActualChart from './layout/ActualChart'
import PracticeChart from './layout/PracticeChart'
import './App.sass'
import { BrowserRouter as Router, Route } from 'react-router-dom'

const App: React.FC = () => {
  return (
    <Router>
      <Route path='/' exact component={Main} />
      <Route path='/practice/chart' component={PracticeChart} />
      <Route path='/actual/chart' component={ActualChart} />
    </Router>
  )
}

export default App