import React from 'react'
import { ChartTableWrapper } from '../styled'
import { WeakCategory } from '../layout/Chart'


interface CahrtWeakCategoryProps {
  weakCategories: WeakCategory[]
}

const ChartWeakCategory: React.FC<CahrtWeakCategoryProps> = ({weakCategories}) => {
  return (
    <ChartTableWrapper>
        <table className="table is-bordered weport-contents-tabel">
        <thead>
          <tr>
            <th>취약 유형</th>
            <th>교재 내 관련 문제</th>
          </tr>
        </thead>
        <tbody>
          {weakCategories.map(category => 
            <tr key={category.categoryName}>
              <td>{category.categoryName}</td>
              <td dangerouslySetInnerHTML={{ __html: category.recommendation}}></td>
            </tr>
          )}          
        </tbody>
        </table>
    </ChartTableWrapper>
  )
}

export default ChartWeakCategory