import React from 'react'
import styled from 'styled-components'

const FieldGroupWrapper = styled.div.attrs(props => ({
  className: 'field is-horizontal'
}))`
  /* input */
  margin-bottom: 8px;
  margin-bottom: 0;

  &.field:not(:last-child) {
    margin-bottom: 8px;
  }

  .field-label.is-normal {
    text-align: left;
    margin-bottom: 0;
    padding-top: 0;
  }

  .field-label .label {
    font-size: 13px;
    color: #000;
    line-height: 30px;
    letter-spacing: -1.04px
  }

  .field-body .input,
  .select:not(.is-multiple),
  .select select {
    width: 100%;
    height: 30px;
  }

  /* select */
  .select select {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 11px;
    font-size: 15px;
    line-height: 28px;
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #000000;
  }

  .select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: #5e7cfe;
  }

  /* p */
  .page-notice {
    position: relative;
    margin-top: 8px;
    padding-left: 7px;
    font-size: 11px;
    color: #666;
    line-height: 1.1;
  }

  .page-notice:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 2px;
    height: 2px;
    background-color: #666;
    border-radius: 50%;
  }

  .page-notice span {
    color: #5e7cfe;
  }

  /* input */
  @media screen and (max-width: 768px) {
    display: flex;

    .field-label {
      flex: 1.5;
      text-align: left;
    }

    .field-body {
      display: flex;
      flex: 4.5;
    }

    .field-body .field {
      flex-shrink: 1;
    }

    .field-body .field:not(.is-narrow) {
      flex-grow: 1;
    }
  }
`


interface FieldGroupProps {
  label: string,
}

const FieldGroup: React.FC<FieldGroupProps> = ({label, children}) => {
  return(
    <FieldGroupWrapper>
      <div className="field-label is-normal">
          <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            {children}
          </div>
        </div>
      </div>
    </FieldGroupWrapper>
  )
}

export default FieldGroup