import React, { MouseEvent, useState, useEffect, Dispatch, SetStateAction } from 'react'
import { Title, WhiteWrapper, Button } from '../styled'
import styled from 'styled-components'
import { postUserInfo } from '../api'
import BookTrial from '../types/Book'
import SessionData from '../session'
import UserInfo from '../types/UserInfo'
import { displayPhone } from '../utils'
import icCheckboxUn from '../assets/ic_checkbox_button_unchecked.png'
import icCheckbox from '../assets/ic_checkbox_button_checked.png'
import btnImage from '../assets/bg_btn.png'
import FieldGroup from '../layout/FieldGroup'

const UserInfoWrapper = styled(WhiteWrapper)`
  padding: 20px 0 0;

  h2${Title} {
    padding: 0 15px;
  }
`
const FieldWrapper = styled.div`
  margin-bottom: 7px;
  padding: 15px 15px 0;
  overflow: hidden;
`

const AgreeCheckBox = styled.div`
  padding: 0 15px;

  .checkbox {
    position: relative;
    padding-left: 21px;
    font-size: 12px;
    color: #000;
    line-height: 15px;
    cursor: pointer;
  }

  .checkbox input[type=checkbox] {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    height: 15px;
    width: 15px;
  }

  .checkbox .check-icon {
    display: block;
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
    background-image: url(${icCheckboxUn});
    background-size: 15px;
    cursor: pointer;
  }

  .checkbox input[type=checkbox]:checked + .check-icon {
    background-image: url(${icCheckbox});
  }
`
const InfoText = styled.div`
  margin-top: 10px;
  padding: 0 15px 18px;

    p {
      position: relative;
      padding-left: 7px;
      font-size: 11px;
      color: #666;
      line-height: 1.36;
    }

    p:before {
      content: '- ';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
    }

      span {
        display: block;
      }
`
const ButtonWrapper = styled.div`
  padding: 20px 15px;
  border-top: 1px solid #eaeaea;
  background-color: #f5f5f5;
`

interface UserInfoProps {
  setUserInfo: Dispatch<SetStateAction<UserInfo | null>>,
  book?: BookTrial | null,
  userInfo?: UserInfo | null
}


const UserInfoForm: React.FC<UserInfoProps> = ({book, setUserInfo, userInfo}) => {
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [checked, setCheck] = useState<boolean>(false)

  useEffect(() => {
    if (userInfo) {
      setPhone(displayPhone(userInfo.phone))
      setName(userInfo.name)
      setCheck(true)

      SessionData.setUserInfo(userInfo)
    }
  }, [userInfo])

  const handleCreateClick = async (e: MouseEvent<HTMLElement>) => {
    if (!name) {
      alert('이름을 입력해주세요.')
      return false
    }

    if (!phone) {
      alert('핸드폰 번호를 입력해주세요.')
      return false
    }

    if (!checked) {
      alert('동의 체크 해주세요.')
      return false
    }

    if (book) {
      const createUser = await postUserInfo({
        trial_id: book.id,
        name,
        phone
      })
      setUserInfo(createUser.data)
      SessionData.setUserInfo(createUser.data)
      const el: HTMLElement | null = document.getElementById('question')
      el && window.scrollTo(0, el.offsetTop)
      
    }
  }
  

  return (
    <UserInfoWrapper>
      <Title>이용 정보 등록</Title>
      <FieldWrapper>
        <FieldGroup label="이름">
          <input className="input"
            id="username-input"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}/>
        </FieldGroup>
        <FieldGroup label="휴대폰번호">
          <input className="input"
            type="text"
            placeholder="010-1234-5678"
            value={phone}
            onChange={e => setPhone(e.target.value)}/>
        </FieldGroup>
      </FieldWrapper>
      <AgreeCheckBox>
        <label className="checkbox">
          <input type="checkbox"
            checked={checked}
            onChange={e => setCheck(e.target.checked)}/>
          <i className="check-icon"></i>
          위 개인정보 수집/이용에 동의합니다.
        </label>
      </AgreeCheckBox>
      <InfoText>
        <p>이벤트 참여를 위해 정보를 수집, 이용하기 위함 입니다.</p>
        <p>신청자는 개인정보 수집을 거부할 권리가 있습니다.
          <span>(단, 동의를 거부하는 경우 이벤트의 참여가 제한될 수 있습니다.)</span>
        </p>
      </InfoText>
      <ButtonWrapper>
        <Button className="gradient" onClick={handleCreateClick}>지금 바로 실력점검+합격예측 하기</Button>
      </ButtonWrapper>
    </UserInfoWrapper>
  )
}

export default UserInfoForm