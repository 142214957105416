import request from './utils/request'
import UserInfo from './types/UserInfo'
import { AnswerList } from './types/Answer'

const baseUrl = 'v2/book/trial/'

export async function isLogin() {
  const { data } = await request({
    url: 'v2/user/me/is_authenticated',
    method: 'get'
  })
  return data.is_authenticated
}

export function getBookList(params: { is_active: boolean }) {
  return request({
    method: 'get',
    url: `${baseUrl}trial/`,
    params
  })
}

export function postUserInfo(data: UserInfo) {
  return request({
    method: 'post',
    url: `${baseUrl}user/`,
    data
  })
}

export function patchUserInfoField(id: number, field: string) {
  return request({
    method: 'patch',
    url: `${baseUrl}user/${id}/`,
    data: { field }
  })
}

export function getAreas(params: any) {
  return request({
    method: 'get',
    url: `${baseUrl}area/`,
    params
  })
}

export function getQuestions(params: { trial: number, area: number }) {
  return request({
    method: 'get',
    url: `${baseUrl}question/`,
    params
  })
}

export function getTimes(params: any) {
  return request({
    method: 'get',
    url: `${baseUrl}time/`,
    params
  })
}

export function postUserAnswers(data: any) {
  return request({
    method: 'post',
    url: `${baseUrl}answers_save/`,
    data
  })
}

interface GetUserAnswersResponse {
  results: AnswerList
}

export function getUserAnswers(params: { user_id: number, area_id: number }) {
  return request.get<GetUserAnswersResponse>(`${baseUrl}answer/`, { params })
}

export function getChartData(params: { area_id: number, trial_user_id?: number, time_id?: number }) {
  return request.get(`${baseUrl}chart/`, { params })
}