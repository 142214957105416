import React from 'react'
import Area from '../types/Area'


function selectedAreaPageString(range: Array<number>): string {
  if (range.length) {
    return `${range[0]}p ~ ${range[1]}p`
  } else {
    return ''
  }
}

interface SelectedAreaDescriptionProps {
  area: Area
}

const SelectedAreaDescription: React.FC<SelectedAreaDescriptionProps> = ({area}) => {
  return (
    <p className="page-notice">{area.name} 영역 문제는
      <span> {selectedAreaPageString(area.page_range)}</span> 입니다.
    </p>
  )
}

export default SelectedAreaDescription