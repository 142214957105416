export default interface Answer {
  id: number | null
  question: number
  answer: string
  userAnswer?: string
  number: number
  correct_rate: number
  score: number
  category_name: string
}

export type AnswerList = Array<Answer>

export type AnswerListRecordByAreaId = Record<number, AnswerList>

export enum AnswerEditorMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW'
}

export type AreaMode = Record<number, AnswerEditorMode>

export enum AnswerType {
  ACTUAL = '실전 모의모사',
  PRACTICE = '영역별 적중 예상 문제'
}