import React, { useState, useEffect, useCallback } from 'react'
import { AnswerListRecordByAreaId, AreaMode, AnswerEditorMode } from '../types/Answer'
import { UserAnswersPerArea } from './AnswerData'
import Area from '../types/Area'
import { AnswerInputGroupOnChangeAction } from './AnswerInputGroup'
import { sortBy } from 'lodash'
import UserAnswerEditor from './UserAnswerEditor'
import {showSpinner, closeSpinner} from './Spinner'


type SavePromise = (areaAnswers: AnswerListRecordByAreaId, value: UserAnswersPerArea) => Promise<any>
type FetchPromise = () => Promise<AnswerListRecordByAreaId>

const emptyRecord: number[] = []

interface UserAnswerEditorContainerProps {
  area: Area
  fetch: FetchPromise
  save: SavePromise
}

const UserAnswerEditorContainer: React.FC<UserAnswerEditorContainerProps> = ({ area, save, fetch, children }) => {
  const [areaAnswers, setAreaAnswers] = useState<AnswerListRecordByAreaId>({})
  const [userAnswersPerArea, setUserAnswersPerArea] = useState<UserAnswersPerArea>({})
  const [mode, setMode] = useState<AreaMode>({})
  
  useEffect(() => {
    const refresh = () => {
      let cancelled = false

      const promise = fetch().then(nextData => {
        if (cancelled) {
          return
        }
        setAreaAnswers(nextData)
      })

      return {
        promise,
        cancel: () => cancelled = true,
      }
    }

    const request = refresh()

    return () => {
      request.cancel()
    }
  }, [fetch])

  const handleModeChange = useCallback((value: AnswerEditorMode) => {
    if (!area) {
      return false
    }
    setMode({...mode, [area.id]: value})
  }, [mode, area])

  const handleAnswerBundleRecordChange = useCallback((action: AnswerInputGroupOnChangeAction) => {
    setUserAnswersPerArea(prev => {
      const prevAnswerBundleRecord = prev[area.id] || emptyRecord

      return {
        ...prev,
        [area.id]: action(prevAnswerBundleRecord),
      }
    })
  }, [area])

  useEffect(() => {
    const inputGroup: UserAnswersPerArea = {}
    const modeTmp: AreaMode = {}
    const areaIDs = Object.keys(areaAnswers)

    for (const areaID of areaIDs) {
      const id = Number(areaID)
      inputGroup[id] = []

      const answers = areaAnswers[id] 
      if (!answers.length) {
        continue
      }
      if (answers[0].id) {
        modeTmp[id] = AnswerEditorMode.VIEW
      } else {
        modeTmp[id] = AnswerEditorMode.CREATE
      }
      inputGroup[id] = sortBy(answers, answer => answer.number)
        .map(answer => parseInt(answer.userAnswer || '', 10))
        .map(value => value ? value : null)
    }

    setUserAnswersPerArea(inputGroup)
    setMode(m => ({...m, ...modeTmp}))
  }, [areaAnswers])


  const handleSave = useCallback(async () => {
    showSpinner()
    const saveResult = await save(areaAnswers, userAnswersPerArea)
    if (!saveResult) {
      closeSpinner()
      return
    }
    const nextData = await fetch()
    setAreaAnswers(nextData)
    handleModeChange(AnswerEditorMode.VIEW)
    closeSpinner()
  }, [userAnswersPerArea, save, fetch, areaAnswers, handleModeChange])

  // TODO
  const answers = areaAnswers[area.id]

  return (
    <UserAnswerEditor 
      answers={answers}
      area={area}
      mode={mode[area.id]}
      onModeChange={handleModeChange}
      value={userAnswersPerArea[area.id] || emptyRecord}
      onChange={handleAnswerBundleRecordChange}
      onSave={handleSave}
    >
      {children}
    </UserAnswerEditor>    
  )
}

export default UserAnswerEditorContainer
