import React, { useState, useEffect, useCallback, useContext, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Notice } from '../styled'
import navPrevImage from '../assets/nav_prev.png'
import navNextImage from '../assets/nav_next.png'
import FieldGroup from '../layout/FieldGroup'
import { AreaListRecordByTime } from '../types/Area'
import { BookContext, UserInfoContext } from '../context'
import { AnswerListRecordByAreaId } from '../types/Answer'
import SelectedAreaDescription from './SelectedAreaDescription'
import { makeUserAnswer, saveAnswers, makeFetchData, UserAnswersPerArea } from './AnswerData'
import {ActualQuery, ActualQueryBuilder} from './query-builder'
import UserAnswerEditorContainer from './UserAnswerEditorContainer'
import { patchUserInfoField } from '../api'
import UserInfo from '../types/UserInfo'
import { isEmptyQuestion } from '../layout/Question'
import { find } from 'lodash'


const Wrapper = styled.div`
`
const TopArea = styled.div`
  padding: 15px;
`
const AreaSelect = styled.div`
  margin-top: 15px;
`
const NavButton = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0 15px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .button.nav-btn {
    background-size: 9px 16px;
    background-repeat: no-repeat;
    text-decoration: none;
    cursor: pointer;
  }

  .nav-btn.prev {
    padding-left: 15px;
    background-image: url(${navPrevImage});
    background-position: center left;
  }

  .nav-btn.next {
    float: right;
    padding-right: 15px;
    background-image: url(${navNextImage});
    background-position: center right;
  }
`

function validateAnswerInputs(userAnswers: AnswerListRecordByAreaId, areas: any) {
  for (const areaId in userAnswers) {
    const answers = userAnswers[areaId]
    for(const answer of answers) {
      if (!answer.userAnswer) {
        const areaName = find(areas, (o) => o.id === Number(areaId)).name
        alert(`${areaName} 영역 정답을 모두 입력해주세요.`)
        return false
      }
    }    
  }
  return true
}

interface ActualProps {
  timeAreas?: AreaListRecordByTime
  setUserInfo: Dispatch<SetStateAction<UserInfo | null>>
}

const Actual: React.FC<ActualProps> = ({timeAreas, setUserInfo}) => {
  const [fieldName, setFieldName] = useState<string>('')
  const [selectedTimeId, setSelectedTimeId] = useState<number>()
  const book = useContext(BookContext)
  const userInfo = useContext(UserInfoContext)  
  const [query, setQuery] = useState<ActualQuery>({ area: null })

  // query
  const selectedArea = query.area
  
  useEffect(() => {
    if (userInfo && userInfo.field) {
      setFieldName(userInfo.field)
    }
  }, [book, userInfo])

  useEffect(() => {
    if (!timeAreas) {
      return
    }
    const firstKey = Number(Object.keys(timeAreas)[0])
    setSelectedTimeId(firstKey)
    setQuery({ area: timeAreas[firstKey][0] })  
  }, [timeAreas])

  useEffect(() => {
    if (book) {
      setFieldName(book.field_list[0])
    }
  }, [book])
  // fetch data

  const fetchData = useCallback<() => Promise<AnswerListRecordByAreaId>>(async () => {
    if (!timeAreas || !selectedTimeId) {
      return Promise.reject()
    }
    const result = await makeFetchData(book, timeAreas[selectedTimeId], userInfo)
    return result
  }, [timeAreas, book, userInfo, selectedTimeId])

  // save data

  const onSave = async (areaAnswers: AnswerListRecordByAreaId, userAnswersPerArea: UserAnswersPerArea) => {
    if (!selectedArea || !userInfo || !userInfo.id || !timeAreas || !selectedTimeId) {
      return false
    }
    const userAnswers = makeUserAnswer(areaAnswers, userAnswersPerArea)
    const validation = validateAnswerInputs(userAnswers, timeAreas[selectedTimeId])
    if (!validation) {
      return false
    } 
  
    await saveAnswers(userAnswers, userInfo)
    await patchUserInfoField(userInfo.id, fieldName)  // 지원 계열사 저장
    setUserInfo({...userInfo, field: fieldName})

    return true
  }

  

  const handleAreaButtonClick = useCallback((direction: string) => {
    if (!timeAreas || !selectedTimeId || !selectedArea) {
      return
    }
    const areas = timeAreas[selectedTimeId]
    const areaIndex = areas.findIndex(area => area.id === selectedArea.id)
    const nextArea = areas[direction === 'next' ? areaIndex + 1 : areaIndex + -1]
    if (nextArea) {
      setQuery({ area: nextArea })
    }
  }, [selectedTimeId, timeAreas, selectedArea])

  if (!timeAreas || !selectedTimeId || !selectedArea) {
    return null
  }

  const areas = timeAreas[selectedTimeId]
  if (!areas) {
    return null
  }

  
  return (
    <Wrapper>
      <ActualQueryBuilder
        areas={timeAreas[selectedTimeId]}
        value={query}
        onChange={setQuery}
      >
        <TopArea>
          <Notice>
            <div className="header">
              {book && book.subject}
              <strong>실력점검+합격예측 서비스 이용 안내</strong>
            </div>
            <div className="body">
              <p>페이지와 문제 번호룰 확인하고 <strong>정답을 입력</strong>해 주세요.</p>
              <p><strong>모든 문항의 정답을 입력</strong>해야만 결과 확인이 가능합니다.</p>
              <p><strong>모든 영역 정답 입력 후</strong> 성적 분석 결과 확인이 가능합니다.</p>
              <p>분석 결과 보기 이후에도 정답 입력 내용 수정이 가능합니다.</p>
            </div>
          </Notice>

          <AreaSelect>
            <FieldGroup label="지원 계열사">
              <div className="select">
                <select onChange={(e) => setFieldName(e.target.value)} value={fieldName}>
                  {book && book.field_list.map(name =>
                    <option key={name} value={name}>
                      {name}
                    </option>
                  )}
                </select>
              </div>
            </FieldGroup>
            <FieldGroup label="회차 선택">
              <div className="select">
                <select onChange={(e) => setSelectedTimeId(Number(e.target.value))}>
                  {timeAreas && Object.keys(timeAreas).map(timeId =>
                    !isEmptyQuestion(timeAreas[Number(timeId)]) && <option key={timeId} value={timeId}>
                      {timeAreas[Number(timeId)][0].trial_time_name}
                    </option>
                  )}
                </select>
              </div>
                {selectedArea && <SelectedAreaDescription area={selectedArea} />}
            </FieldGroup>
          </AreaSelect>
        </TopArea>

      </ActualQueryBuilder>
      
      <UserAnswerEditorContainer 
        fetch={fetchData}
        save={onSave}
        area={selectedArea}
      >
        <NavButton>
          <button className="button is-text nav-btn prev" onClick={() => handleAreaButtonClick('behind')}>이전 영역</button>
          <button className="button is-text nav-btn next" onClick={() => handleAreaButtonClick('next')}>다음 영역</button>
        </NavButton>
      </UserAnswerEditorContainer>

      
    </Wrapper>
  )
}

export default Actual