import React from 'react'
import styled from 'styled-components'
import { ChartDivGroup } from '../styled'
import { RefineChartData, WeakCategory } from '../layout/Chart'
import ChartWeakCategory from './ChartWeakCategory'


const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
  color: #000;
  font-weight: bold;
`

interface ChartRecommendedContentProps {
  weakCategories: WeakCategory[]
}

const ChartRecommendedContent: React.FC<ChartRecommendedContentProps> = ({weakCategories}) => {
  if (!weakCategories.length) {
    return null
  }
  return (
    <ChartDivGroup style={{marginTop: '10px'}}>
      <Title>취약유형 극복 위포트 추천 컨텐츠</Title>
      <ChartWeakCategory weakCategories={weakCategories}/>
    </ChartDivGroup>
  )
}

export default ChartRecommendedContent