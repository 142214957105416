import React, { useState, MouseEvent, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import Modal from './BookSelectModal'
import { BookItem } from './BookSelectModal'
import BookTrial, { BookTrialList } from '../types/Book'
import { Title, WhiteWrapper, Button } from '../styled'


interface SelectProps {
  setBook: Dispatch<SetStateAction<BookTrial | null>>,
  book?: BookTrial | null,
  bookList: BookTrialList
}


const SeletedBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 15px;
`

const BookItemMain = styled(BookItem)`
  border: 1px solid #e5e5e5;
`

const BookSelect: React.FC<SelectProps> = ({setBook, book, bookList}) => {
  const [isActive, setActive] = useState()

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setActive(true)
  }  

  return (
    <WhiteWrapper>
      <Title>교재 선택</Title>
      <SeletedBtn>
        <Button className="is-text" onClick={handleClick}>선택하기</Button>
      </SeletedBtn>  
      {book && 
      <BookItemMain>
        <img src={book.list_image} alt=""></img>
        <p>
          <span>{book.subject}</span>
        </p>
      </BookItemMain>}
      
      <Modal
        setBook={setBook}
        bookList={bookList}
        isActive={isActive}
        setActive={setActive}
        ></Modal>
    </WhiteWrapper>
  )
}

export default BookSelect
