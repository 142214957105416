import React, { Fragment } from 'react'
import styled from 'styled-components'
import { ChartDivGroup, ChartTableWrapper } from '../styled'
import Area from '../types/Area'
import { chunk } from 'lodash'
import { ChartData } from '../layout/Chart'

function makeCorrectPercent(target: number, total: number) {
  const percent = (target / total) * 100
  return `${percent.toFixed(1)}%`
}

interface ChartUserAnswerViewProps {
  area: Area
  data: ChartData[]
  score: { me: number, total: number }
}

const ChartUserAnswerView: React.FC<ChartUserAnswerViewProps> = ({area, data, score}) => {
  const columnCount = 3
  const emptyColumns = [...Array(columnCount).keys()]
  const tableData = chunk(data.slice(1), columnCount)
  
  return (
    <ChartDivGroup>
      <ChartTableWrapper>
        {tableData.map((columns, index) => {
        
          return (
            <table className="table is-bordered" key={index}>
              <colgroup>
                  <col width="19%" />
                  <col width="15%" />
                  <col width="12%" />
                  <col width="15%" />
                  <col width="12%" />
                  <col width="15%" />
                  <col width="12%" />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2}>구분</th>
                  {emptyColumns.map(i => <th key={i} colSpan={2}>{columns[i] && columns[i].category_name}</th>)}
                </tr>
                <tr>
                  {emptyColumns.map(i => 
                    <Fragment key={i}>
                      <th>{columns[i] && '총점'}</th>
                      <th>{columns[i] && '정답률'}</th>
                    </Fragment>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>나의 정답</th>
                  {emptyColumns.map(i => 
                    <Fragment key={i}>
                      <td className="total">{columns[i] && columns[i].user_score.toFixed(1)}</td>
                      <td className="percent">{columns[i] && makeCorrectPercent(columns[i].user_correct_count, columns[i].question_count)}</td>
                    </Fragment>
                  )}
                </tr>
                <tr>
                  <th>상위 10%</th>
                  {emptyColumns.map((col, i) => 
                    <Fragment key={i}>
                      <td>{columns[i] && columns[i].upto_10_score.toFixed(1)}</td>
                      <td>{columns[i] && makeCorrectPercent(columns[i].upto_10_correct_count, columns[i].question_count)}</td>
                    </Fragment>
                  )}
                </tr>
                <tr>
                  <th>상위 20%</th>
                  {emptyColumns.map(i => 
                    <Fragment key={i}>
                      <td>{columns[i] && columns[i].upto_20_score.toFixed(1)}</td>
                      <td>{columns[i] && makeCorrectPercent(columns[i].upto_20_correct_count, columns[i].question_count)}</td>
                    </Fragment>
                  )}
                </tr>
                <tr>
                  <th>상위 30%</th>
                  {emptyColumns.map(i => 
                    <Fragment key={i}>
                      <td>{columns[i] && columns[i].upto_30_score.toFixed(1)}</td>
                      <td>{columns[i] && makeCorrectPercent(columns[i].upto_30_correct_count, columns[i].question_count)}</td>
                    </Fragment>
                  )}
                </tr>
                <tr>
                  <th>평균</th>
                  {emptyColumns.map(i => 
                    <Fragment key={i}>
                      <td>{columns[i] && Number(columns[i].total_score).toFixed(1)}</td>
                      <td>{columns[i] && makeCorrectPercent(columns[i].total_correct_count, columns[i].question_count)}</td>
                    </Fragment>
                  )}
                </tr>
              </tbody>
            </table>
          )}
        )}
      </ChartTableWrapper>
    </ChartDivGroup>
  )
}

export default ChartUserAnswerView