import React, { useMemo, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { Button, Table } from '../styled'
import { AnswerList, AnswerEditorMode } from '../types/Answer'
import { BookContext } from '../context'
import { UserInfoContext } from '../context'
import useReactRouter from 'use-react-router'
import Area from '../types/Area'
import BookTrial  from '../types/Book'

const Wrapper = styled.div`
`
const ScoreWrapper = styled.div`
  margin-top: 5px;
  padding: 0 15px;
`
const ScoreBox = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 12px;
  background-color: #f5f5f5;
  border: 1px solid #5e7cfe;
  border-radius: 3px;
  font-size: 15px;
  color: #000;
  text-align: center;

  strong {
    color: #f94983;
  }
`
const ButtonWrapper = styled.div`
  padding: 20px 15px;
  border-top: 1px solid #eaeaea;
  background-color: #f5f5f5;
`
const TableWrapper = styled.div`
  margin-top: 5px;
  padding: 0 15px;
`

interface UserAnswerViewProps {
  answers: AnswerList
  tableColumns: number
  onMode: (value: AnswerEditorMode) => any
  area: Area
}

const UserAnswerView: React.FC<UserAnswerViewProps> = ({answers, tableColumns, onMode, area, children}) => {
  const { history } = useReactRouter()
  const book: BookTrial | null = useContext(BookContext)
  const userInfo = useContext(UserInfoContext)
  const tableCountRange = useMemo(() => [...Array(Math.ceil(answers.length/tableColumns)).keys()], [answers, tableColumns])
  const score = useMemo(() => {
    const result = { me: 0, total: 0 }
    answers.forEach(answer => {
      result.total += answer.score
      if (answer.answer === answer.userAnswer) {
        result.me += answer.score
      }
    })
    return result
  }, [answers])

  const handleChartClick = useCallback(async () => {
    // if (area.trial_time) {
    //   alert('7월 중 추가 제공 예정입니다.')
    //   return
    // }
    if (area.trial_time) {
      if (book && book.actual_chart_active) {
        history.push('/actual/chart', {area, userInfo, score})
        return
      }
    } else {
      if (book && book.practice_chart_active) {
        history.push('/practice/chart', {area, userInfo, score})
        return
      }
    }
    alert('서비스 추가 제공 예정입니다.')
  }, [area, score, userInfo, history])

  return (
    <Wrapper>
      <ScoreWrapper>
        <ScoreBox>
          <span>{area.name}</span> 채점 결과 : <strong>{ score.me }점</strong> / { score.total }점
        </ScoreBox>
      </ScoreWrapper>
      <TableWrapper>
        {tableCountRange.map(rowNum => {
          const rows = answers.slice(rowNum * tableColumns, (rowNum + 1) * tableColumns)
          const emptyColumns = [...Array(tableColumns - rows.length).keys()]

          return (
            <Table key={rowNum}>
              <table className="table is-bordered">
                <colgroup>
                  <col width="25%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    {rows.map((answer, i) =>
                      <th key={i}>{answer.number}</th>
                    )}
                    {emptyColumns.map((n) => <th key={n}></th>)}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>상세 유형</td>
                    {rows.map((answer, i) =>
                      <td key={i}>{answer.category_name}</td>
                    )}
                    {emptyColumns.map((n) => <th key={n}></th>)}
                  </tr>
                  <tr>
                    <td>본인 정답</td>
                    {rows.map((answer, i) =>
                      <td key={i}>{answer.userAnswer}</td>
                    )}
                    {emptyColumns.map((n) => <th key={n}></th>)}
                  </tr>
                  <tr>
                    <td>정/오답</td>
                    {rows.map((answer, i) => {
                      const correct = answer.userAnswer === answer.answer ? 'o' : 'x'
                      return (<td key={i} className={correct}>{correct.toUpperCase()}</td>)
                    })}
                    {emptyColumns.map((n) => <th key={n}></th>)}
                  </tr>
                  <tr>
                    <td>정답률</td>
                    {rows.map((answer, i) =>
                      <td key={i}>{answer.correct_rate.toFixed(0)}%</td>
                    )}
                    {emptyColumns.map((n) => <th key={n}></th>)}
                  </tr>
                </tbody>
              </table>
            </Table>
          )
        })}

      </TableWrapper>
      {children}
      <ButtonWrapper className="hal">
        <Button className="half left" onClick={handleChartClick}>상세 분석 결과 확인</Button>
        <Button className="half right nomal" onClick={() => onMode(AnswerEditorMode.UPDATE)}>정답 재입력</Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default UserAnswerView
