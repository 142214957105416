import { getChartData } from '../api'
import {showSpinner} from '../components/Spinner'
import { useEffect, useState } from 'react'
import { isLogin } from '../api'
import useReactRouter from 'use-react-router'
import UserInfo from '../types/UserInfo'
import Area from '../types/Area'
import { Location } from 'history'


export function makeRefineChartData(data: ChartData[]): RefineChartData {
  const result: RefineChartData = {
    myRateOfAllCategory: [],
    upto_10_PercentRateOfAllCategory: [],
    upto_20_PercentRateOfAllCategory: [],
    upto_30_PercentRateOfAllCategory: [],
    total_PercentRateOfAllCategory: [],
    labels: [],
    weakCategories: {
      upto_10_PercentRateOfAllCategory: [],
      upto_20_PercentRateOfAllCategory: [],
      upto_30_PercentRateOfAllCategory: [],
      total_PercentRateOfAllCategory: []
    }
  }

  for(const item of data) {
    if (item.category_name === '전체') {
      continue
    }
    const total = item.question_count
    const myRate = Number(((item.user_correct_count / total) * 100).toFixed(0))
    const totalPercentRate = Number(((item.total_correct_count / total) * 100).toFixed(0))
    const upto_10_PercentRate = Number(((item.upto_10_correct_count / total) * 100).toFixed(0))
    const upto_20_PercentRate = Number(((item.upto_20_correct_count / total) * 100).toFixed(0))
    const upto_30_PercentRate = Number(((item.upto_30_correct_count / total) * 100).toFixed(0))

    if (myRate < totalPercentRate) {
      result.weakCategories.total_PercentRateOfAllCategory.push({
        categoryName: item.category_name,
        recommendation: item.recommendation
      })
    }
    if (myRate < upto_10_PercentRate) {
      result.weakCategories.upto_10_PercentRateOfAllCategory.push({
        categoryName: item.category_name,
        recommendation: item.recommendation
      })
    }
    if (myRate < upto_20_PercentRate) {
      result.weakCategories.upto_20_PercentRateOfAllCategory.push({
        categoryName: item.category_name,
        recommendation: item.recommendation
      })
    }
    if (myRate < upto_30_PercentRate) {
      result.weakCategories.upto_30_PercentRateOfAllCategory.push({
        categoryName: item.category_name,
        recommendation: item.recommendation
      })
    }

    result.labels.push(item.category_name)
    result.myRateOfAllCategory.push(myRate)
    result.total_PercentRateOfAllCategory.push(totalPercentRate)
    result.upto_10_PercentRateOfAllCategory.push(upto_10_PercentRate)
    result.upto_20_PercentRateOfAllCategory.push(upto_20_PercentRate)
    result.upto_30_PercentRateOfAllCategory.push(upto_30_PercentRate)
  }
  return result
}

export interface ChartData {
  category_name: string
  question_count: number
  question_score: number
  recommendation: string
  total_correct_count: number
  total_score: number
  upto_10_correct_count: number
  upto_10_score: number
  upto_20_correct_count: number
  upto_20_score: number
  upto_30_correct_count: number
  upto_30_score: number
  user_correct_count: number
  user_score: number
  previous_year_candidate_score: number
}

export interface WeakCategory {
  categoryName: string
  recommendation: string
}

interface WeakCategories {
  [key: string]: WeakCategory[]
  upto_10_PercentRateOfAllCategory: WeakCategory[],
  upto_20_PercentRateOfAllCategory: WeakCategory[],
  upto_30_PercentRateOfAllCategory: WeakCategory[],
  total_PercentRateOfAllCategory: WeakCategory[]
}

export interface RefineChartData {
  [key: string]: string[] | number[] | WeakCategories
  labels: string[]
  myRateOfAllCategory: number[]
  upto_10_PercentRateOfAllCategory: number[]
  upto_20_PercentRateOfAllCategory: number[]
  upto_30_PercentRateOfAllCategory: number[]
  total_PercentRateOfAllCategory: number[]
  weakCategories: WeakCategories
}

export interface LocationProps {
  area: Area
  userInfo: UserInfo
  score: { me: number, total: number }
}

export function useChart(location: Location<LocationProps>, setData: any) {
  const { history } = useReactRouter()
  const [locationState] = useState<LocationProps>(location.state)

  const locationBack = () => {
    history.goBack()
  }

  useEffect(() => {
    isLogin()
      .then(result => {
        if (!result) {
          window.location.href = 'https://weport.co.kr/user/login?service=weport&next=https://passcheck.weport.co.kr'
          return 
        }
        if (!locationState) {
          alert('이용 정보를 등록해야 이용 가능합니다.')
          history.push('/')
          return
        }
      })
    return () => {}
  },[])

  useEffect(() => {
    if (!locationState) {
      return
    }
    const getData = async () => {
      const result = await getChartData({
        area_id: locationState.area.id,
        time_id: locationState.area.trial_time,
        trial_user_id: locationState.userInfo.id
      })
      setData(result.data.result)
    }
    showSpinner()
    getData()
    return () => {}
  }, [location])

  return {
    locationState,
    locationBack
  }
}
