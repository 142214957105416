import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { AreaList, AreaListRecordByTime } from '../types/Area'
import Actual from '../components/Actual'
import Practice from '../components/Practice'
import { getAreas } from '../api'
import { partition, groupBy } from 'lodash'
import { BookContext } from '../context'
import UserInfo from '../types/UserInfo'
import Spinner from '../components/Spinner'
import { UserInfoContext } from '../context'

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;

    .tabs:not(:last-child) {
      margin-bottom: 0;
    }

    .tabs ul {
      padding: 0 14px;
    }

    .tabs ul li {
      width: 50%;
    }

    .tabs li a {
      font-size: 15px;
      font-weight: bold;
    }

    .tabs li.is-active a {
    }
`

const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  z-index: 1;

  .blur-txt {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: #000;
    text-align: center;
  }
`

const TabContents = styled.div`

`

export function isEmptyQuestion(areas: AreaList) {
  for (const area of areas) {
    if (!area.question_count) {
      return true
    }
  }
  return false
}

interface QuestionLayoutProps {
  setUserInfo: Dispatch<SetStateAction<UserInfo | null>>
}

const QuestionLayout: React.FC<QuestionLayoutProps> = ({setUserInfo}) => {
  const [tabName, setTab] = useState('practice')
  const [practiceAreas, setPracticeAreas] = useState<AreaList>([])
  const [actualAreas, setActualAreas] = useState<AreaListRecordByTime>()
  const [tabActive, setTabActive] = useState(true)
  const book = useContext(BookContext)
  const userInfo = useContext(UserInfoContext)

  useEffect(() => {
    if (book) {
      const fetchAreas = async () => {
        const { data } = await getAreas({ trial_id: book.id })

        const divide = partition(data.results, o => o.type === 1)
        const practiceAreas = divide[0]
        const actualAreas = divide[1]
        
        const actualTimeAreas:AreaListRecordByTime = groupBy(actualAreas, 'trial_time')

        setPracticeAreas(practiceAreas)
        setActualAreas(actualTimeAreas)

        // 영역별 문제, 실전 모의고사 둘 다 문제가 하나라도 있어야 탭 적용
        const firstKey = Number(Object.keys(actualTimeAreas)[0])
        const practiceActive = !isEmptyQuestion(practiceAreas)
        const actualActive = !isEmptyQuestion(actualTimeAreas[firstKey])
        const tabActive = practiceActive && actualActive

        if (!tabActive) {
          practiceActive && setTab('practice')
          actualActive && setTab('actual')
        }
        setTabActive(practiceActive && actualActive)
      }
      fetchAreas()
    }    
    
  }, [book])

  return (
    <Wrapper id="question">
      {userInfo && <Spinner />}      
      { !userInfo && 
      <BlurOverlay>
        <p className="blur-txt">이용 정보 등록 후 <br />서비스 이용이 가능합니다.</p>
      </BlurOverlay>}
      {tabActive &&
      <div className="tabs">
        <ul>          
          <li className={tabName === 'practice' ? 'is-active' : ''}>
            <a href="#answer" onClick={() => setTab('practice')}>영역별 적중 예상 문제</a>
          </li>
          <li className={tabName === 'actual' ? 'is-active' : ''}>
            <a href="#answer" onClick={() => setTab('actual')}>실전 모의고사</a>
          </li>
        </ul>
      </div>}
      
      {book && 
      <TabContents id="answer">
          <div style={{display: tabName === 'actual' ? 'block' : 'none'}}>
            <Actual timeAreas={actualAreas} setUserInfo={setUserInfo}/>
          </div>
          <div style={{display: tabName === 'practice' ? 'block' : 'none'}}>
            <Practice areas={practiceAreas} />
          </div>
      </TabContents>}
    </Wrapper>
  )
}

export default QuestionLayout